import { graphql } from 'gatsby';
import React from 'react';
import NewsSection from '../components/shared/sections/news-section';
import Seo from '../components/config/Seo';
import HeaderSection from '../components/shared/sections/header-section';
import useGetNewsPageTranslation from '../hooks/useGetNewsPageTranslation';

const News = ({ data }) => {
  const {
    pageTitle,
    headerSection,
  } = useGetNewsPageTranslation();

  return (
    <>
      <Seo title={pageTitle} />
      <HeaderSection
        title={headerSection.title}
        description={headerSection.description}
      />
      <NewsSection
        data={data.allContentfulNewsEntry.edges}
      />
    </>
  );
};

export default News;

export const query = graphql`
  query NewsPage {
    allContentfulNewsEntry(
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          image {
            fluid(quality: 85) {
              ...GatsbyContentfulFluid
            }
          }
          category
          title
          text {
            text
          }
          date(formatString: "dddd, MMM Do - YYYY")
          slug
          cardWidth
          preamble {
            preamble
          }
        }
      }
    }
  }
`;
